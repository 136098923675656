import * as React from "react"
import Seo from '../components/seo'
import Layout from "../components/layout"
import DefaultContent from "../components/PrivacyPolicy"
import WhoWeAre from "../components/WhoWeAre"
import { graphql } from "gatsby"

export default function DefaultTemplate(pageData) {
  const data = pageData?.data
  let seo = pageData?.pageContext?.node?.seo
  let translatedURI = pageData?.pageContext?.node?.translations[0]?.uri

  return (
    <>
      <Seo data={seo}/>
      <Layout translatedURI={translatedURI}>
        <WhoWeAre
          title={data.wpPage.pageDescription?.pageDescription}
          name={data.wpPage?.title}
          description=""
        />
        <DefaultContent data={data.wpPage.content} />
      </Layout>
    </>
  )
}

export const pageQuery = graphql`
  query pageData8($databaseId: Int) {
    wpPage(databaseId: { eq: $databaseId }) {
      title
      content
      pageDescription {
        pageDescription
      }
    }
  }
`
