import React from "react"
import "./privacyPolicy.scss"
import { createMarkup } from "../../../utils"

export default function DefaultContent(props) {
  return (
    <>
      <section className="rules privacypolicy">
        <div className="container">
          <div className="rule-wrapper" dangerouslySetInnerHTML={createMarkup(props.data)}></div>
        </div>
      </section>
    </>
  )
}

